<template>
  <div>
    <h2 class="mb-3">طلبات بحاجة معالجة</h2>
    <!-- <card-statistics-group/> -->
    <b-row>
      <b-col v-for="item in statisticsItems" :key="item.icon">
        <b-card no-body class="card-statistics cursor-pointer" @click="$router.push({ name: item.route })">
          <div v-if="item.title" style="position: absolute; top: -15px; right: -10px">
            <b-avatar size="40" class="shadow" variant="danger">
              {{ item.title }}
            </b-avatar>
          </div>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col md="3" sm="6" class="mb-2 mb-md-0" :class="item.customClass">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" :variant="item.color">
                      <feather-icon size="24" :icon="item.icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-xl-2 mb-0 mr-2" style="font-size: 20px;width: 200px;">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay
    id="overlay-background"
    :show="loadingQ"
    variant="white"
    opacity="0.7"
    blur="2px">
    <b-card >
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="">زوار بالإنتظار</h2>
        <b-button variant="primary" class="btn-icon" style="height: 40px;" @click="reFetchQueue()">
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </div>
      <hr />
      <b-row>
        <b-col v-for="(item, id) in QueueTv" :key="id" cols="3">
          <div class=" mb-1" style=" height: 70px;">
            <q-item style="width: 240px !important;" :item="item"></q-item>
          </div>
        </b-col>
        <div v-if="!loadingQ && QueueTv.length == 0" class="mb-0 w-100 my-2 bg-light-primary rounded-sm shadow-sm">
          <div class="alert-body p-1">
            <feather-icon icon="InfoIcon" size="16" class="mx-50" />
            <span>عذراً لا يوجد زوار بالإنتظار</span>
          </div>
          <div class="w-100 rounded-sm alert-dropdown"></div>
        </div>
      </b-row>
    </b-card>
    </b-overlay>
  </div>
</template>
<script>
import DataTable from "@/components/data-table/index";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BOverlay
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import QItem from "@/components/q-item.vue"
import { debounce } from "lodash";

export default {
  components: {
    QItem,
    BCard,
    DataTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BButton,
    BOverlay
  },
  data: () => {
    return {
  
      fields: [{ key: "" }],
      statisticsItems: [
        {
          icon: "SettingsIcon",
          color: "light-primary",
          title: "0",
          subtitle: " حجوزات الإجراءات ",
          customClass: "mb-2 mb-xl-0",
          route: "bookings-procedure",
        },
        {
          icon: "ActivityIcon",
          color: "light-info",
          title: "0",
          subtitle: "حجوزات القاعات",
          customClass: "mb-2 mb-xl-0",
          route: "bookings-halls",
        },
        {
          icon: "CalendarIcon",
          color: "light-danger",
          title: false,
          subtitle: " حجوزات الفعاليات",
          customClass: "mb-2 mb-sm-0",
          route: "bookings-events",
        },
      ],
    };
  },
  methods: {
    ...mapActions("publicPages/screenTV", ["getQueueTv"]),
    ...mapActions("manageApp/home", ["getBookings", "homeStatistics"]),
    ...mapActions("manageCenter/bookings/activites", ["getActivity"]),
    reFetchQueue() {
        this.getQueueTv()   
    }
  },

  computed: {
    ...mapGetters("publicPages/screenTV", ["QueueTv", "loadingQ"]),
    ...mapGetters("manageApp/home", ["bookings", "statistics"])
  },

  created() {

    this.homeStatistics().then(() => {
      this.statisticsItems[0].title = this.statistics.procedure_bookings
      this.statisticsItems[1].title = this.statistics.hall_bookings

    })
  },
  mounted() {
    this.getQueueTv()
    setInterval(() => { this.getQueueTv(true) }, 20000)
  }
};
</script>